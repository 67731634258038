import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import {
  ClientSBannerControllerService,
  ResponseModelListSBannerDTO,
  SystemBannerControllerService,
} from '@soctrip/angular-banner-service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-promotion-banner',
  templateUrl: './promotion-banner.component.html',
  styleUrls: ['./promotion-banner.component.scss'],
})
export class PromotionBannerComponent {
  @Input() code: string;
  @Input() iframeClass: string;
  @Input() imgClass: string;
  @Input() country: string;
  @Input() imageDefault = '';
  @Output() hiddenBanner: EventEmitter<any> = new EventEmitter();
  @Output() getDataBanner: EventEmitter<any> = new EventEmitter();

  listPromotionBanners: any[] = [];
  dataBanner: ResponseModelListSBannerDTO;
  baseUrl = environment.baseURL;
  responsiveOptions: any;
  slideshows: any[] = [];
  loading: boolean = true;
  isShow: boolean = false;
  isShowNextButton = false;
  isInitial: boolean = false;

  private clientBannerService = inject(ClientSBannerControllerService);
  private systemBannerService = inject(SystemBannerControllerService);
  private route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.getBannerInfo();

    this.responsiveOptions = [
      {
        breakpoint: '1200',
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: '798px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '486px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
    this.isInitial = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInitial && changes['country'] && changes['country'].currentValue) {
      this.getBannerInfo();
    }
  }

  getBannerInfo() {
    this.route.queryParamMap.subscribe((queryParams) => {
      const bannerId = queryParams.get('preview_banner');
      const targetCode = queryParams.get('banner_code');
      if (!this.country) return;

      if (bannerId && targetCode?.toUpperCase() === this.code.toUpperCase()) {
        this.getPreviewBannerById(bannerId); // get Preview banner by Id
      } else {
        this.getOnGoingBanner(); //get banner on going
      }
    });
  }

  getOnGoingBanner() {
    this.clientBannerService
      .getBannerByCode(this.code, this.country)
      .subscribe({
        next: (res) => {
          if (res.success) {
            // const banners = res.data ? res.data : [];
            // banners.forEach(banner => {
            //   let media = banner.medias ? banner.medias[0] : undefined;
            //   if(media && media.id) {
            //     media.url = banner.url;
            //     this.listPromotionBanners.push(media);
            //   }
            // });
            this.listPromotionBanners = res.data ? res.data : [];
            if (this.listPromotionBanners.length > 3) {
              this.isShowNextButton = true;
            } else {
              this.isShowNextButton = false;
            }
            this.loading = false;
            if (this.listPromotionBanners.length == 0)
              this.hiddenBanner?.emit(true);
          } else {
            this.loading = false;
            this.hiddenBanner?.emit(true);
          }
        },
        error: (err) => {
          console.log(err);
          this.loading = false;
          this.hiddenBanner?.emit(true);
        },
      });
  }

  getPreviewBannerById(bannerID: string) {
    this.systemBannerService.getBannerById(bannerID).subscribe({
      next: (res) => {
        if (res.success) {
          const media = res.data?.medias?.[0];
          if (media) {
            this.listPromotionBanners.push(res.data);
            if (this.listPromotionBanners.length > 3) {
              this.isShowNextButton = true;
            } else {
              this.isShowNextButton = false;
            }
          } else {
            this.hiddenBanner?.emit(true);
          }
          this.loading = false;
        } else {
          this.loading = false;
          this.hiddenBanner?.emit(true);
        }
      },
      error: (err) => {
        this.loading = false;
        console.log(err);
        this.hiddenBanner?.emit(true);
      },
    });
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/background/background.png';
  }

  openInNewTab(url: string): void {
    if (url) {
      window.location.href = url;
    }
  }

  onPage(event: any) {
    if (event.page > 0) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  handleDateToShow(checkIn: string, checkOut: string): string {
    if (checkIn != undefined && checkOut != undefined) {
      const startYear = new Date(checkIn).getFullYear();
      const endYear = new Date(checkOut).getFullYear();
      const startMonth = new Date(checkIn).getMonth();
      const endMonth = new Date(checkOut).getMonth();
      if (startYear === endYear) {
        if (startMonth === endMonth) {
          return `${formatDate(checkIn, 'dd', 'en-US')}-${formatDate(
            checkOut,
            'dd/MM',
            'en-US'
          )}`;
        } else {
          return `${formatDate(checkIn, 'dd/MM', 'en-US')}-${formatDate(
            checkOut,
            'dd/MM',
            'en-US'
          )}`;
        }
      } else {
        return `${formatDate(checkIn, 'dd/MM/yyyy', 'en-US')}-${formatDate(
          checkOut,
          'dd/MM/yyyy',
          'en-US'
        )}`;
      }
    }
    return '';
  }

  getShortMonth(monthNumber: number): string {
    const date = new Date(2000, monthNumber - 1, 1);
    const shortMonth: string = date.toLocaleString('en-US', { month: 'short' });
    return shortMonth;
  }

  setNumVisible(listPromotion: any[]) {
    if (listPromotion.length < 2) {
      return 1;
    } else if (listPromotion.length < 3) {
      return 2;
    }

    return 3;
  }
}
