import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss']
})
export class GuestInfoComponent {
  @Input() type: string;
  @Input() flightDetail: any;
  @Input() segments: any;

  tooltipElement: HTMLElement | null = null;
  tooltipWidth: number = 0;
  tooltipHeight: number = 0;
  key: string;

  constructor(private translateService: TranslateService,) { }

  ngOnInit() {
    this.key = this.type === 'DEPART' ? 'departure' : 'return';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type'] && changes['type'].currentValue) {
      this.key = this.type === 'DEPART' ? 'departure' : 'return';
    }
  }

  onMouseEnter(event: MouseEvent, frequentFlyerProgram: any, frequentFlyerNumber: any) {
    const x = event.pageX;
    const y = event.pageY;
    const html = `
      <div class="!px-4 !py-3 bg-white rounded-lg text-black font-semibold text-xs shadow-lg opacity-1 max-w-xs">
          <div>
              <p class="font-inter text-xs !font-semibold text-palette-gray-500">${this.translateService.instant('FLIGHT.PASSENGERS.MEMBERSHIP')}</p>
              <div class="font-inter text-sm !font-normal text-palette-gray-900">
                  <img />
                  <span>${frequentFlyerProgram}</span>
                  ${frequentFlyerNumber ? `<span> - </span><span>${frequentFlyerNumber}</span>` : ''}
              </div>
          </div>
      </div>
    `

    if (!this.tooltipElement) {
      this.tooltipElement = document.createElement('div');
      this.tooltipElement.innerHTML = html;
      this.tooltipElement.style.position = 'absolute';
      this.tooltipElement.style.zIndex = '9999';
      document.body.appendChild(this.tooltipElement);
      this.tooltipWidth = this.tooltipElement.offsetWidth;
      this.tooltipHeight = this.tooltipElement.offsetHeight;
    }

    this.tooltipElement.style.left = x - 73 + 'px';
    this.tooltipElement.style.top = y - 77 + 'px';
  }

  onMouseLeave() {
    if (this.tooltipElement && this.tooltipElement.parentNode) {
      this.tooltipElement.parentNode.removeChild(this.tooltipElement);
      this.tooltipElement = null;
    }
  }


  public isNumber(value: any): boolean {
    return typeof value === 'number';
  }
}
