import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-membership',
  templateUrl: './tooltip-membership.component.html',
  styleUrls: ['./tooltip-membership.component.scss'],
})
export class TooltipMembershipComponent {
  @Input() passenger: any;

  tooltipStyle: { [key: string]: string } = {};
  isShowMembershipTooltip = false;

  showMemberShipTooltipInfo(event?: any) {
    this.calculateTooltipPosition(event);
    this.isShowMembershipTooltip = true;
  }

  hideMemberShipTooltipInfo() {
    this.isShowMembershipTooltip = false;
  }

  calculateTooltipPosition(event: any) {
    const elementRect = event.target?.getBoundingClientRect();
    const elementTop = elementRect.top;
    const elementHeight = elementRect.height;
    const windowHeight = window.innerHeight;
    const spaceAtBottom = windowHeight - (elementTop + elementHeight);
    const spaceAtTop = elementTop;

    if (spaceAtBottom < spaceAtTop) {
      this.tooltipStyle = {
        bottom: '24px',
      };
    } else {
      this.tooltipStyle = {
        top: '24px',
      };
    }
  }

}
