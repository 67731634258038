<div 
    *ngIf="passenger?.memberShip?.isMemberShip && (passenger?.memberShip?.depart?.frequentFlyerNumber || passenger?.memberShip?.return?.frequentFlyerNumber)"
    (mouseenter)="showMemberShipTooltipInfo($event)"
    (mouseleave)="hideMemberShipTooltipInfo()"
    class="!w-5 !h-5 flex items-center justify-center text-white rounded-full 
        bg-palette-amber-500 font-inter text-xs hover:cursor-pointer">
    M
    <ng-container *ngIf="isShowMembershipTooltip">
        <div class="absolute bottom-6 border !flex flex-col !gap-2 border-palette-gray-200 rounded-lg !px-4 !py-3 bg-white z-[14]" [ngClass]="tooltipStyle">
            <p class="font-inter !font-semibold text-xs text-palette-gray-500">{{ 'FLIGHT.PASSENGERS.MEMBERSHIP' | translate }}</p>
            <div *ngIf="passenger?.memberShip?.depart?.frequentFlyerProgram" class="!flex !gap-1 items-center">
                <img class="!h-[30px] !w-[30px] max-w-none bg-palette-gray-200 rounded-md" [src]="passenger?.memberShip?.depart?.frequentFlyerProgram?.logo" />
                <div class="font-inter whitespace-nowrap !font-normal text-sm text-palette-gray-900">
                    {{ passenger?.memberShip?.depart?.frequentFlyerProgram?.airline_name }}
                    <span class="uppercase" *ngIf="passenger?.memberShip?.depart?.frequentFlyerNumber">
                         - {{ passenger?.memberShip?.depart?.frequentFlyerNumber }}
                    </span>
                </div>
            </div>
            <div *ngIf="passenger?.memberShip?.return?.frequentFlyerProgram" class="!flex !gap-1 items-center">
                <img class="!h-[30px] !w-[30px] max-w-none bg-palette-gray-200 rounded-md" [src]="passenger?.memberShip?.return?.frequentFlyerProgram?.logo" />
                <div class="font-inter whitespace-nowrap !font-normal text-sm text-palette-gray-900">
                    {{ passenger?.memberShip?.return?.frequentFlyerProgram?.airline_name }}
                    <span class="uppercase" *ngIf="passenger?.memberShip?.return?.frequentFlyerNumber">
                         - {{ passenger?.memberShip?.return?.frequentFlyerNumber }}
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</div>