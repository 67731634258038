import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
    @Input() items: any[] = [];
    @Input() itemsToShow: number = 3;
    @Input() rotate: boolean = false;
    @Input() itemsToScroll: number = 1;
    @Input() gap: number = 0;
    @Input() breakpoints: any[] = [];
    @Input() itemTemplate?: TemplateRef<any>;
    @Input() style: string = '';
    @Input() id: string = '';

    @ViewChild('carouselWrapper') carouselWrapper!: ElementRef;

    isLoading: boolean = true;
    itemsDisplay: any[] = [];
    itemWidth: number = 0;
    currentIndex: number; // Current position of the carousel
    adjustedItemsToShow: number;
    isShowNext: boolean = true;
    isShowBack: boolean = true;

    constructor(private cdr: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.calculateItemWidth(); // Calculate the width after the view is initialized
        this.isLoading = false;
    }

    ngOnInit() {
        this.initData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items']) {
            this.initData();
        }
    }

    calculateItemWidth() {
        const containerWidth = this.carouselWrapper.nativeElement.offsetWidth;
        this.itemWidth = (containerWidth - (this.adjustedItemsToShow - 1) * this.gap) / this.itemsToShow;
        this.cdr.detectChanges();
    }

    initData(): void {
        // Init and clone ceil items to show
        const screenWidth = window.innerWidth;
        const index = this.breakpoints.findIndex((item) => {
            return screenWidth >= item.width;
        })

        if (index !== -1) {
            this.itemsToShow = this.breakpoints[index].itemsToShow;
        }
        this.adjustedItemsToShow = Math.ceil(this.itemsToShow);

        if (this.items.length < this.itemsToScroll) {
            this.itemsToScroll = 1;
        }
        // Handle in mode rotate or not rotate
        if (this.rotate && this.items.length >= this.itemsToShow) {
            this.cloneItems();
            this.currentIndex = Math.ceil(this.itemsToShow);
        } else {
            this.itemsDisplay = [...this.items];
            this.currentIndex = 0;
        }
        setTimeout(() => {
            this.scrollIntoItem(false);
        })
    }

    // Clone the items first and last to make scroll infinite smooth
    cloneItems(): void {
        const startClone = this.items.slice(-this.adjustedItemsToShow);
        const endClone = this.items.slice(0, this.adjustedItemsToShow);
        this.itemsDisplay = [...startClone, ...this.items, ...endClone];
    }

    scrollIntoItem(smooth: boolean = true) {
        const container = this.carouselWrapper.nativeElement as HTMLElement;
        const target = document.getElementById(this.id + '_carousel_item_' + this.currentIndex) as HTMLElement;

        if (container && target) {
            // Calculate position item scroll to
            const targetPosition = target.offsetLeft - container.offsetLeft;

            // Scroll to item target
            container.scrollTo({
                left: targetPosition,
                behavior: smooth ? 'smooth' : 'auto' // Smooth scroll ?
            });
        }
    }


    // Navigate to the previous item
    onBack() {
        // Logic in mode not rotate
        if (!this.rotate) {
            if (this.currentIndex === 0) {
                return;
            }
            if (this.currentIndex - this.itemsToScroll <= 0) {
                this.currentIndex = 0;
            } else {
                this.currentIndex -= this.itemsToScroll;
            }
            this.scrollIntoItem();
            return;
        }

        // Logic in mode rotate
        if (this.currentIndex - this.itemsToScroll >= 0) {
            this.currentIndex -= this.itemsToScroll;
            this.scrollIntoItem();
        } else {
            // Handle smooth rotate
            this.currentIndex = this.itemsDisplay.length - (this.adjustedItemsToShow * 2 - this.currentIndex);
            this.scrollIntoItem(false);

            setTimeout(() => {
                this.currentIndex -= this.itemsToScroll;
                this.scrollIntoItem();
            });
        }
    }

    // Navigate to the next item
    onNext() {
        // Logic in mode not rotate
        if (!this.rotate) {
            if (this.currentIndex === this.itemsDisplay.length - this.itemsToShow) {
                return;
            }
            if (this.currentIndex + this.itemsToScroll > this.items.length - this.adjustedItemsToShow) {
                this.currentIndex = this.items.length - this.adjustedItemsToShow + 1;
            } else {
                this.currentIndex += this.itemsToScroll;
            }
            
            this.scrollIntoItem();
            return;
        }

        // Logic in mode rotate
        if (this.currentIndex + this.itemsToScroll <= this.itemsDisplay.length - this.adjustedItemsToShow) {
            this.currentIndex += this.itemsToScroll;
            this.scrollIntoItem();
        } else {
            // Handle smooth rotate
            this.currentIndex = this.currentIndex - (this.itemsDisplay.length - this.adjustedItemsToShow * 2);
            this.scrollIntoItem(false);

            setTimeout(() => {
                this.currentIndex += this.itemsToScroll;
                this.scrollIntoItem();
            });
        }
    }

    // Handle window resize
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.initData();
        this.calculateItemWidth();
    }
}
