<ng-container *ngIf="!loading">
    <app-carousel [items]="listPromotionBanners" [itemsToShow]="3" [itemsToScroll]="1" [gap]="16" [itemTemplate]="customTemplate"
        [id]="'promotion-banner'" [breakpoints]="[{ width: 1024, itemsToShow: 3 }, { width: 540, itemsToShow: 2 }, { width: 0, itemsToShow: 1 }]">
    </app-carousel>
    <ng-template #customTemplate let-item let-index="index">
        <div class="banner relative h-full !max-h-[208px]">
            <div class="w-full h-full relative">
                <ng-container *ngIf="item.medias[0].extension == '.svg'; else imgTemplate">
                    <iframe [src]="(baseUrl + '/storage/files/download/' + item.medias[0].id + '.svg') | safeUrl"
                        class="h-full w-full object-cover mx-auto rounded-lg {{ iframeClass }} cursor-pointer"></iframe>
                </ng-container>
                <ng-template #imgTemplate>
                    <img [src]="baseUrl + '/storage/files/web/' + item.medias[0].id + '.webp'"
                        class="rounded-lg object-cover {{ imgClass }} max-h-full w-full cursor-pointer"
                        (click)="openInNewTab(item.medias[0].url)" (error)="replaceWithDefaultImage($event)" />
                </ng-template>
                <div
                    class="absolute right-1 top-1 flex rounded-[20px] bg-white px-2 py-[1px] text-center text-[13px] font-medium text-palette-blue-500 shadow-md">
                    {{ handleDateToShow(item.start_time,item.end_time) }}
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>