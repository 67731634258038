import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OverlayModule } from 'primeng/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { HeaderService } from '@modules/booking/services/header/header.service';
import { UserInfo } from '@modules/users/models/user';
import { UserService } from 'src/app/core/services/user/user.service';
import { DialogModule } from 'primeng/dialog';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { HeaderLibModule } from '@soctrip-common/master-menu';
import { CurrencyService } from '@modules/users/services/currency.service';
import { CountryCodeService } from 'src/app/services/country-code.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    /* @primeng */
    MenubarModule,
    ButtonModule,
    CommonModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    DividerModule,
    OverlayModule,
    DialogModule,
    TranslateModule,
    SharedModule,
    HeaderLibModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderBookingComponent implements OnInit {
  @ViewChildren('fullName') fullNameElements: QueryList<ElementRef>;
  @ViewChild('searchElement') searchElement: ElementRef;
  urlParts: string[] = [];
  currentPage = 'booking';
  scrolled = false;
  headerElement: HTMLElement;
  classIconNavigate =
    'h-10 px-1 lg:px-2 lg:space-x-2 flex justify-center items-center hover:bg-black hover:bg-opacity-[0.15] rounded-md cursor-pointer';
  menuItemColor = '';
  openSearch = false;
  searchValue = '';
  cartNumberBadge = 5;
  noText: boolean;
  isVoice: boolean;
  isShowHeader: boolean;
  isFixedHeader: boolean;
  isWhite: boolean;
  isChangeColorHeader: boolean;
  overlayVisible = false;
  overlayUserVisible = false;
  overlayMoreVisible = false;
  visiblePopupLogout = false;
  visiblePopupDeactivateAccount = false;
  visibleSelectLang = false;
  visibleShoppingCart = false;
  userInfo: UserInfo;
  isLogin: boolean | null;
  bookingTypeList: any = [];
  menuList: any = [];
  isMiniMenuOpen = false;
  urlTravelAssistant = environment.TRAVEL_ASSIST_URL;
  isShowBookingNav = false;
  menuListTabletScreen: any = [];
  public fullNameArray: any;
  baseUrl = environment.BASE_PATH;
  environment = environment;
  megaVisible = false;
  myBookingUrl = environment.MY_FLIGHT_BOOKINGS;
  currentTheme = 'darkTheme';
  loginReturnUrl = window.location.href
  currentLang: string;
  timeZone: string;
  timeZones: any = {
    "VN": "Asia/Ho_Chi_Minh",
    "US": "America/New_York",
    "FR": "Europe/Paris",
    "JP": "Asia/Tokyo",
    "DE": "Europe/Berlin"
  };
  bannerCountry: any;
  isShowBanner: boolean;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private countryCodeService: CountryCodeService

  ) {}
  ngOnInit() {
    this.handleShowBanner();
    this.countryCodeService.getCountryCode().subscribe({
      next: (countryCode: string | null) => {
        if (countryCode) {
          this.bannerCountry = countryCode;
          this.timeZone = this.timeZones[this.bannerCountry];
        }
      },
    });
    this.initData();
    this.getUserInfor();
        this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loginReturnUrl = window.location.href;
      }
    });
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.urlParts = e.url.split('/');
        this.headerElement = document.getElementById('menu-bar') as HTMLElement;
        this.handleScroll();
        this.handleShowBanner();
      }
    });
    this.headerService.getPropertyHeader().subscribe((data) => {
      if (data.isShow != undefined) {
        this.isShowHeader = data.isShow;
      }
      if (data.isFixed != undefined) {
        this.isFixedHeader = data.isFixed;
      }
      if (data.isChangeColor != undefined) {
        this.isChangeColorHeader = data.isChangeColor;
      }
      if (data.isWhite != undefined) {
        this.isWhite = data.isWhite;
      }
    });

    this.userService.getLoginStatus().subscribe((data) => {
      if (data !== null) {
        this.isLogin = data;
      }
    });
    this.currentLang = localStorage.getItem('unauthLang') || environment.languages[0]

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    })
  }

  handleShowBanner() {
    const refusePath = ['search', 'my-booking'];
    const url = window.location.href.split('?')[0];
    this.isShowBanner = !refusePath.some(path => url.includes(`/${path}`));
  }

  getUserInfor() {
    this.userService.getUserInfor().subscribe((user) => {
      if (user) this.userInfo = user;
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  updateLangAndCurrency(e: any) {
    setTimeout(() => {
      this.currencyService.sendSignalChangeCurrency();
    }, 500);
  }

  public handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      const isTruncated =
        val.nativeElement.scrollWidth > val.nativeElement.clientWidth;
      if (isTruncated) {
        this.renderer.addClass(val.nativeElement, className);
      } else {
        this.renderer.removeClass(val.nativeElement, className);
      }
    }
  }

  public mouseOverHotelName() {
    this.fullNameArray = this.fullNameElements.toArray();
    this.handleShowTooltip(this.fullNameArray, 'full-name');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.handleScroll();
  }
  handleMenuBar() {
    if (this.isShowHeader) {
      if (this.scrolled || this.isWhite) {
        this.currentTheme = '';
        return 'bg-white text-palette-gray-500 shadow-md';
      } else {
        this.currentTheme = 'darkTheme';
        return 'gray-header bg-black bg-opacity-[0.32]';
      }
    } else {
      return 'hidden';
    }
  }
  handleClickNav(event: Event, menuItemName: string) {
    if (menuItemName == 'booking') {
      // this.bookingList.onShow(event);
      this.overlayVisible = !this.overlayVisible;
    } else {
      this.currentPage = menuItemName;
      this.router.navigate(['/' + menuItemName]);
    }
  }
  showNoText() {
    this.noText = true;
    this.isVoice = false;
  }
  showVoice() {
    this.isVoice = !this.isVoice;
    this.noText = false;
    alert('This feature is developing.');
  }
  handleScroll() {
    if (!this.headerElement) return;
    if (this.headerElement && window.scrollY > 50 && this.urlParts.length < 4) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }
  handleClickTypeBooking(typeBooking: string) {
    switch (typeBooking) {
      case 'hotel':
        window.location.href = environment.BOOKING_URL;
        break;
      case 'car-rental':
        window.location.href = environment.CAR_URL;
        break;
      case 'tours':
        window.location.href = environment.TRAVEL_ASSIST_URL;
        break;
      default:
        // handle 'flight' case
        this.currentPage = 'booking';
        this.overlayVisible = !this.overlayVisible;
        this.router.navigate(['/' + this.currentPage + '/' + typeBooking]);
        break;
    }
  }

  changeColorMenuItem(menuItemName: string): string {
    let className = '';
    if (this.currentPage == menuItemName) {
      if (this.scrolled || this.isWhite) {
        className = 'text-palette-blue-600 font-semibold bg-palette-blue-50';
      } else {
        className = 'bg-palette-gray-900 bg-opacity-[0.4] font-semibold';
      }
    }
    return className;
  }
  getIcon(menuItem: any) {
    if (this.scrolled || this.isWhite) {
      if (this.currentPage == menuItem.menu_item_short_name) {
        return menuItem.blue_icon;
      } else {
        return menuItem.gray_icon;
      }
    } else {
      if (this.currentPage == menuItem.menu_item_short_name) {
        return menuItem.active_white_icon;
      } else {
        return menuItem.white_icon;
      }
    }
  }
  handleSearchIcon() {
    this.openSearch = !this.openSearch;
    setTimeout(() => {
      if (this.openSearch && this.searchElement) {
        this.searchElement.nativeElement.focus();
      }
    }, 0);
  }
  getClassSearch() {
    if (this.openSearch) {
      if (this.scrolled || this.isWhite) {
        return 'border rounded-full border-blue-palette-300 shadow-xs';
      } else {
        return 'border rounded-full';
      }
    }
    return '';
  }

  onCloseSearch() {
    this.noText = false;
    this.isVoice = false;
    this.openSearch = false;
    this.searchValue = '';
  }
  handleSearch() {
    // TODO
    alert('This feature is developing.');
  }
  handleClearSearch() {
    this.searchValue = '';
    this.searchElement.nativeElement.focus();
  }
  handleClickMenuMore() {
    this.overlayMoreVisible = !this.overlayMoreVisible;
  }
  onClickMyBookingItem() {
    this.router.navigate(['my-booking']);
  }
  onClickSavedListItem() {
    window.location.href = environment.TRAVEL_ASSIST_URL + '/saved-list';
  }

  onClickReviews() {
    this.router.navigate(['reviews']);
  }

  onSignUp() {
    this.isMiniMenuOpen = false;
    this.router.navigate(['sign-up']);
  }
  onSignIn() {
    this.isMiniMenuOpen = false;
    window.location.href = environment.SOCIAL_APP_URL + '/login?r='+ encodeURIComponent(window.location.href);
  }
  displayPopupLogout(): void {
    this.visiblePopupDeactivateAccount = false;
    this.visiblePopupLogout = true;
    this.overlayVisible = false;
  }

  closePopupLogout(): void {
    this.visiblePopupLogout = false;
  }
  toggleCustomSetting() {
    this.overlayUserVisible = !this.overlayUserVisible;
  }

  handleSignOut(): void {
    window.location.href = environment.SOCIAL_APP_URL + '/login';
    localStorage.removeItem(environment.AUTH_STATUS);
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.USER_PROFILE);
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.USER_ROLE_KEY);
    this.visiblePopupLogout = !this.visiblePopupLogout;
    this.userService.setUserInfo(null);
    this.userService.setUserProfile(null);
    this.userService.setLoginStatus(false);
  }
  handleOpenMiniMenu(): void {
    this.isMiniMenuOpen = true;
    document.body.style.overflow = 'hidden';
    document.body.style.marginRight = '10px';
  }
  handleCloseMiniMenu(): void {
    this.isMiniMenuOpen = false;
    document.body.style.overflow = 'auto';
    document.body.style.marginRight = '0px';
    this.isShowBookingNav = false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const windowWidth = window.innerWidth;
    if (windowWidth > 768) {
      this.isMiniMenuOpen = false;
      document.body.style.overflow = 'auto';
    }
  }
  handleStopPropagation(event: Event): void {
    event.stopPropagation();
  }
  handleShowBookingNav(): void {
    this.isShowBookingNav = !this.isShowBookingNav;
  }

  onClickMyServices() {
    this.router.navigate(['ota']);
  }

  initData() {
    this.bookingTypeList = [
      {
        id: '1',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        short_name: 'flight',
        icon: 'sctr-icon-plane text-palette-blue-700',
      },
      {
        id: '2',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        short_name: 'hotel',
        icon: 'sctr-icon-building-01 text-palette-pink-600',
      },
      {
        id: '3',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        short_name: 'car-rental',
        icon: 'sctr-icon-car text-palette-orange-500',
      },
      {
        id: '4',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        short_name: 'tours',
        icon: 'sctr-icon-umbrella-03 text-palette-purple-600',
      },
    ];
    this.menuList = [
      {
        id: '1',
        menu_item_name: 'HEADER.MENU.HOME',
        menu_item_short_name: 'home',
        menu_item_url: environment.SOCIAL_APP_URL,
        white_icon: 'new-feeds.svg',
        active_white_icon: 'active-new-feeds.svg',
        gray_icon: 'gray-new-feeds.svg',
        blue_icon: 'blue-new-feeds.svg',
      },
      {
        id: '2',
        menu_item_name: 'HEADER.MENU.BOOKING',
        menu_item_short_name: 'booking',
        white_icon: 'booking.svg',
        active_white_icon: 'active-booking.svg',
        gray_icon: 'gray-booking.svg',
        blue_icon: 'blue-booking.svg',
      },
      {
        id: '4',
        menu_item_name: 'HEADER.MENU.FOOD',
        menu_item_short_name: 'food',
        menu_item_url: environment.ECOMMERCE_FOOD_URL,
        white_icon: 'food.svg',
        active_white_icon: 'active-food.svg',
        gray_icon: 'gray-food.svg',
        blue_icon: 'blue-food.svg',
      },
      {
        id: '5',
        menu_item_name: 'HEADER.MENU.SHOPPING',
        menu_item_short_name: 'shopping',
        menu_item_url: environment.ECOMMERCE_URL,
        white_icon: 'shopping.svg',
        active_white_icon: 'active-shopping.svg',
        gray_icon: 'gray-shopping.svg',
        blue_icon: 'blue-shopping.svg',
      },
    ];
    this.menuListTabletScreen = [
      {
        id: '1',
        menu_item_short_name: 'travel',
        menu_item_name: 'Travel Assistant',
        click_function: () => (window.location.href = this.urlTravelAssistant),
        menu_item_icon: 'sctr-icon-star-06',
      },
      {
        id: '2',
        menu_item_short_name: 'booking',
        menu_item_name: 'My Booking',
        click_function: () => {
          this.handleCloseMiniMenu();
          this.router.navigate(['my-booking']);
        },
        menu_item_icon: 'sctr-icon-building-07',
      },
      {
        id: '3',
        menu_item_short_name: 'saved',
        menu_item_name: 'Saved List',
        click_function: () => {
          this.handleCloseMiniMenu();
          window.location.href = environment.TRAVEL_ASSIST_URL + '/saved-list';
        },
        menu_item_icon: 'sctr-icon-bookmark',
      },
      {
        id: '4',
        menu_item_short_name: 'services',
        menu_item_name: 'Soctrip center',
        click_function: () => {
          this.handleCloseMiniMenu();
          this.onClickMyServices();
        },
        menu_item_icon: '',
      },
      {
        id: '5',
        menu_item_short_name: 'logout',
        menu_item_name: 'Sign Out',
        click_function: () => {
          this.handleCloseMiniMenu();
          this.displayPopupLogout();
        },
        menu_item_icon: 'sctr-icon-log-out-01',
      },
    ];
  }
}

