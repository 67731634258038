<div class="flex items-center relative">
    <!-- Back button -->
    <button *ngIf="(rotate || currentIndex > 0) && itemsDisplay.length >= itemsToShow"
        (click)="onBack()" 
        class="group rounded-full absolute left-4 z-[10] !w-10 !h-10 bg-white flex justify-center items-center transition-all">
        <i class="sctr-icon-chevron-left text-2xl leading-6 text-palette-gray-500 group-hover:text-black"></i>
    </button>
  
    <!-- Items Wrapper -->
    <div class="overflow-x-hidden w-full {{style}}" [id]="'carousel_wrapper_' + id" #carouselWrapper>
        <div class="flex" [style.gap.px]="gap">
            <ng-container *ngFor="let item of itemsDisplay; let index = index">
                <div 
                    class="carousel-item" 
                    [id]="id + '_carousel_item_' + index"
                    [style.width.px]="itemWidth"
                    [style.minWidth.px]="itemWidth">
                    <ng-container *ngIf="itemTemplate; else defaultTemplate"
                        [ngTemplateOutlet]="itemTemplate"
                        [ngTemplateOutletContext]="{ $implicit: item, index: index }">
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>

  
    <!-- Next button -->
    <button *ngIf="(rotate || currentIndex < itemsDisplay.length - itemsToShow) && itemsDisplay.length >= itemsToShow"
        (click)="onNext()" 
        class="group rounded-full absolute right-4 z-[10] !w-10 !h-10 bg-white flex justify-center items-center transition-all">
        <i class="sctr-icon-chevron-right text-2xl leading-6 text-palette-gray-500 group-hover:text-black"></i>
    </button>
</div>

<ng-template #defaultTemplate let-item>
    <div class="default-item">
        <p>{{ item }}</p>
    </div>
</ng-template>